/* 캘린더 전체 컨테이너 */
.calendar-container {
	padding: 10px;
}

/* PC 버전 테이블 스타일 */
.calendar-container.desktop .ui.table {
	width: 100%;
	border-collapse: collapse;
}

/* 캘린더 셀 스타일 */
.calendar-cell {
	padding: 10px;
	min-height: 100px; /* 최소 높이 설정 */
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* 날짜 스타일 */
.calendar-day {
	font-weight: bold;
	font-size: 16px;
}

/* 모바일 스타일 */
.calendar-container.mobile {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

/* 모바일에서 개별 날짜 카드 스타일 */
.calendar-container.mobile .calendar-cell {
	border: 1px solid #ddd;
	border-radius: 8px;
	padding: 10px;
	background: #f9f9f9;
}

/* 주 번호 (Week Number) 스타일 */
.week-number-cell {
	background-color: #f1f1f1;
	font-weight: bold;
	text-align: center;
	vertical-align: middle;
	min-width: 50px;
}

/* 주 총 근무시간 표시 */
.week-work-time {
	font-size: 12px;
	color: #333;
	margin-top: 5px;
}
